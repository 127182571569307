.toc-section {
  background-color: #fff;
  margin: 5px 0;
  padding: 10px 30px 10px 5px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  width: max-content;
}

.project-thumb {
  float: right;
  padding-left: 10px;
}
.project-thumb img {
  display: block;
  width: 200px;
}

.project-content {
  display: block;
  float: left;
}